




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import { Role } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import Fields from '@/components/form/Fields.vue'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields
  },
  apollo: {
    savedRole: {
      query: gql`query getRole ($roleId : ID) {
        savedRole: role(roleId: $roleId) {
          _id
          environmentId
          createdAt
          name
        }
      }`,
      variables () {
        return {
          roleId: this.componentId
        }
      }
    },
    environment: {
      query: gql`query getEnvironmentProfileFields ($environmentId : ID) {
        environment(environmentId: $environmentId) {
          _id
          serializedProfileSchema
        }
      }`,
      variables () {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class RoleEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId !: String
  @Prop({ type: String, required: true }) componentId !: String

  savedRole : Role | null = null
  role : Partial<Role> = {}

  saving = false
  valid = false

  @Watch('savedRole')
  update (newData : Role) {
    this.$set(this, 'role', _cloneDeep(this.savedRole))
  }

  @Watch('role.name')
  updateName (newName : string) {
    this.$emit('name', newName)
  }


  async save () {
    if (!this.role || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($roleId : ID, $role : UpdateRoleInput) {
          updateRole (roleId: $roleId, role: $role) {
            _id
            environmentId
            createdAt
            name
          }
        }`,
        // Parameters
        variables: {
          roleId: this.role._id,
          role: {
            name: this.role.name
          }
        }
      })

      this.savedRole = result.data.updateRole
      this.$emit('save', result.data.updateRole)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este rol del ambiente?')) return
    if (!this.role || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($roleId : ID) {
          removeRole (roleId: $roleId)
        }`,
        // Parameters
        variables: {
          roleId: this.role._id
        }
      })
      this.$emit('delete', result.data.removeRole)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  get dirty () {
    return !_isEqual(this.role, this.savedRole)
  }

  dismiss () {
    this.$emit('dismiss')
  }
}
